export const PoolType = {
    HAD : "主客和",
    FHA : "半場主客和",
    HHA : "讓球主客和",
    HDC : "讓球",
    HIL : "入球大細",
    FHL : "半場入球大細",
    CHL : "開出角球大細",
    CRS : "波膽",
    FCS : "半場波膽",
    FTS : "第一隊入球",
    TTG : "總入球",
    OOE : "入球單雙",
    HFT : "半全場",
    SGA : "同場過關",
    NTS : "下一隊入球",
    MSP : "特別項目",
  }
  
  
  
  export enum BetItemShortText {
    X = "和",
    H = "主隊",
    A = "客隊",
    N = "無入球",
    O = "雙",
    E = "單",
    HOAN = "主其他(客無入球)",
    HOAS = "主其他(客有入球)",
    DO = "和其他",
    AOHN = "客其他(主無入球)",
    AOHS = "客其他(主有入球)",
  }
  
  export enum BetCountItem {
    H = "大",
    L = "細",
  }
  
  export const HADText = {
    "1": "主",
    "2": "客",
    X: "和",
    H: "主隊",
    A: "客隊",
    N: "無入球",
  };
  
  export const BetCmbID = {
    "1": "主隊勝",
    "2": "客隊勝",
  };
  
  export const LeagueName = {
    美國職業聯賽: "美職聯",
    女子美國職業聯賽: "美職女",
    女子墨西哥超級聯賽: "墨超女",
    墨西哥超級聯賽: "墨超",
    墨西哥甲組聯賽: "墨甲",
    女子德國甲組聯賽: "德甲女",
    德國甲組聯賽: "德甲",
    德國乙組聯賽: "德乙",
    女子西班牙甲組聯賽: "西甲女",
    西班牙乙組聯賽: "西乙",
    意大利甲組聯賽: "意甲",
    瑞典超級聯賽: "瑞超",
    英格蘭超級聯賽: "英超",
    西班牙甲組聯賽: "西甲",
    荷蘭乙組聯賽: "荷乙",
    荷蘭甲組聯賽: "荷甲",
    澳洲職業聯賽: "澳職",
    女子澳洲職業聯賽: "澳職女",
    亞冠精英盃: "亞冠盃",
    日本職業聯賽: "日職聯",
    韓國職業聯賽: "韓職聯",
    日本乙組聯賽: "日乙",
    法國甲組聯賽: "法甲",
    法國乙組聯賽: "法乙",
    巴西甲組聯賽: "巴甲",
    阿根廷甲組聯賽: "阿甲",
    葡萄牙超級聯賽: "葡超",
    挪威超級聯賽: "挪超",
    比利時甲組聯賽: "比甲",
    智利甲組聯賽: "智甲",
    俄羅斯超級聯賽: "俄超",
    蘇格蘭超級聯賽: "蘇超",
    英格蘭冠軍聯賽: "英冠",
    歐洲聯賽冠軍盃 : "歐聯",
    英格蘭甲組聯賽: "英甲",
    女子意大利盃 : "意盃女",
    女子南韓職業聯賽 : "韓職女",
    英格蘭聯賽錦標: "英聯錦",
    俄羅斯盃 : "俄盃"
  };
  
  export const MatchStatus = {
    "FIRST HALF": "上半場",
    "SECOND HALF": "下半場",
    "HALF TIME": "半場",
    "PENDING" : "未開賽"
  };
  