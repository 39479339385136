import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import MuiTab from "@mui/material/Tab";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const TabName = styled("span")(({ theme }) => ({
  lineHeight: 1.71,
  fontSize: "0.875rem",
  marginLeft: theme.spacing(1.0),
  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",  // Smaller font size for medium screens
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",  // Smaller font size for small screens
  },
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: 100,
    padding: "6px 12px",  // Adjust padding for medium screens
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: 67,
    padding: "4px 8px",  // Adjust padding for small screens
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: 50,  // Further reduction for extra small screens
    padding: "3px 6px",  // Adjust padding for extra small screens
  },
}));

export const SoccerDateTab = ({
  soccerDate,
  selectedSoccerDate,
  onSeclectedSoccerDate,
}) => {
  return (
    <div style={{ marginBottom: '10px', display: soccerDate?.length > 0 ? "" : "none" }}>
      <TabContext value={selectedSoccerDate}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            overflowX: 'auto',  // Allow horizontal scrolling on smaller screens
          }}
        >
          <TabList
            onChange={onSeclectedSoccerDate}
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',  // Prevent tabs from wrapping to a new line
            }}
          >
            {soccerDate?.map((value) => (
              <Tab
                key={value}
                value={value}
                label={value}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
    </div>
  );
};