import React, { memo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import Grid from "@mui/material/Grid2"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';

// 數據卡片組件
const StatCard = memo(({ title, value, subtitle, icon: Icon }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography color="textSecondary" variant="body2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" component="div">
            {value}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        </Box>
        {Icon && (
          <Icon color="primary" sx={{ fontSize: 40 }} />
        )}
      </Box>
    </CardContent>
  </Card>
));

// 進球分布圖表
const GoalsDistributionChart = memo(({ data }) => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h6" gutterBottom>進球時間分布</Typography>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="goals" fill="#2196f3" />
      </BarChart>
    </ResponsiveContainer>
  </Paper>
));

// 射門效率圖表
const ShotEfficiencyChart = memo(({ data }) => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h6" gutterBottom>射門效率</Typography>
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </Paper>
));

// 詳細數據表格
const DetailedStats = memo(({ homeStats, awayStats }) => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h6" gutterBottom>詳細數據對比</Typography>
    {Object.entries(homeStats).map(([key, value], index) => (
      <Box key={key} sx={{ my: 2 }}>
        <Grid container alignItems="center">
          <Grid size={{xs: 3}}>
            <Typography variant="body2" align="right">{value}</Typography>
          </Grid>
          <Grid  size={{xs: 6}}>
            <Box sx={{ px: 2 }}>
              <Typography variant="body2" align="center" color="textSecondary">
                {key}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    flex: value,
                    height: 8,
                    bgcolor: 'primary.main',
                    borderRadius: '4px 0 0 4px'
                  }}
                />
                <Box
                  sx={{
                    flex: awayStats[key],
                    height: 8,
                    bgcolor: 'secondary.main',
                    borderRadius: '0 4px 4px 0'
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid size={{xs: 3}}>
            <Typography variant="body2">{awayStats[key]}</Typography>
          </Grid>
        </Grid>
        {index < Object.entries(homeStats).length - 1 && <Divider sx={{ my: 1 }} />}
      </Box>
    ))}
  </Paper>
));

export const StatisticsTab = memo(() => {
  // 模擬數據
  const goalsDistribution = [
    { time: '0-15', goals: 5 },
    { time: '16-30', goals: 8 },
    { time: '31-45', goals: 6 },
    { time: '46-60', goals: 10 },
    { time: '61-75', goals: 7 },
    { time: '76-90', goals: 9 },
  ];

  const shotEfficiency = [
    { name: '進球', value: 35, color: '#4caf50' },
    { name: '射正', value: 45, color: '#2196f3' },
    { name: '射偏', value: 20, color: '#ff9800' },
  ];

  const comparisonStats = {
    home: {
      '控球率': 60,
      '射門': 15,
      '射正': 8,
      '角球': 7,
      '越位': 3,
      '犯規': 12,
    },
    away: {
      '控球率': 40,
      '射門': 10,
      '射正': 4,
      '角球': 5,
      '越位': 2,
      '犯規': 14,
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={3}>
        {/* 數據卡片行 */}
        <Grid size={{xs: 12, md: 3}}>
          <StatCard
            title="總進球"
            value="35"
            subtitle="本賽季"
          />
        </Grid>
        <Grid size={{xs: 12, md: 3}}>
          <StatCard
            title="勝率"
            value="68%"
            subtitle="近10場"
          />
        </Grid>
        <Grid size={{xs: 12, md: 3}}>
          <StatCard
            title="場均射門"
            value="14.5"
            subtitle="本賽季"
          />
        </Grid>
        <Grid size={{xs: 12, md: 3}}>
          <StatCard
            title="場均控球"
            value="58%"
            subtitle="本賽季"
          />
        </Grid>

        {/* 圖表行 */}
        <Grid size={{xs: 12, md: 6}}>
          <GoalsDistributionChart data={goalsDistribution} />
        </Grid>
        <Grid size={{xs: 12, md: 6}}>
          <ShotEfficiencyChart data={shotEfficiency} />
        </Grid>

        {/* 詳細數據對比 */}
        <Grid size={{xs: 12}}>
          <DetailedStats
            homeStats={comparisonStats.home}
            awayStats={comparisonStats.away}
          />
        </Grid>
      </Grid>
    </Box>
  );
}); 