import { srAxios } from "../utils/fetch";
import { Soccer } from "../model/soccer";
import { EventBanner } from "../model/eventBanner";

export const getSoccerList = (MatchDate: string) =>
  srAxios.get<Soccer[]>("/.netlify/functions/GetUpcomingSoccerListHandler",  {
    params: {
      MatchDate: MatchDate
    }
  });

export const getBanner = (EventId: string) =>
  srAxios.get<EventBanner[]>("/.netlify/functions/GetSoccerEventHandler", {
    params: {
      EventId: EventId
    }
  })
  export const getMatchDateList = () =>
    srAxios.get<string[]>("/.netlify/functions/GetUpcomingMatchDateHandler")