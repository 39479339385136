import React, { memo } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Box,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { teal } from "@mui/material/colors";

// 樣式常量
const styles = {
  leagueHeader: {
    mt: 1,
    px: 2,
    py: 1,
    backgroundColor: teal[400],
    color: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  matchCard: {
    margin: "0 auto",
    padding: 0,
    borderRadius: 3,
    mb: 2,
    mt: 1,
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  chip: {
    fontSize: "0.75rem",
    height: 24,
    borderRadius: 12,
  },
  teamAvatar: {
    width: 40,
    height: 40,
    margin: "0 auto",
    marginBottom: 1,
    borderRadius: "50%",
  },
};

// 比賽信息頭部組件
const MatchHeader = memo(({ value }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: 1,
    }}
  >
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {value.leagueName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {value.frontendID}
      </Typography>
    </Box>
    <MatchStatus value={value} />
  </Box>
));

// 比賽狀態組件
const MatchStatus = memo(({ value }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    }}
  >
    <StatusChips value={value} />
    {value.matchStatus !== "未開賽" && value.corner && (
      <CornerCount corner={value.corner} />
    )}
  </Box>
));

// 狀態標籤組件
const StatusChips = memo(({ value }) => (
  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
    {value.isESOffer && <Chip label="派彩快" color="info" sx={styles.chip} />}
    {value.chlInplay && <Chip label="角球" color="error" sx={styles.chip} />}
    {value.inPlay && <Chip label="即場" color="primary" sx={styles.chip} />}
    <Chip
      label={value.kickOffTime}
      color="warning"
      sx={{ ...styles.chip, fontSize: "0.85rem" }}
    />
  </Box>
));

// 角球計數組件
const CornerCount = memo(({ corner }) => (
  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
    <span role="img" aria-label="corner-flag" style={{ marginRight: 4 }}>
      ⛳️
    </span>
    <Typography variant="body2" sx={{ color: "gray", fontWeight: "bold" }}>
      {corner}
    </Typography>
  </Box>
));

// 球隊信息組件
const TeamInfo = memo(({ teamName, teamLogo }) => (
  <Grid size={{xs: 4}} sx={{ textAlign: "center" }}>
    <Avatar
      alt={teamName}
      src={`https://images.footylogic.com/teams/${teamLogo}.png`}
      sx={styles.teamAvatar}
    />
    <Typography variant="body2" sx={{ marginTop: 1, fontWeight: "bold" }}>
      {teamName}
    </Typography>
  </Grid>
));

// 比分信息組件
const ScoreInfo = memo(({ value }) => (
  <Grid size={{xs:4}} sx={{ textAlign: "center" }}>
    <Typography
      variant="body1"
      sx={{ fontWeight: "bold", color: "#f78a0a", fontSize: "1.5rem" }}
    >
      {value.homeTeamFullScore} - {value.awayTeamFullScore}
    </Typography>
    <Typography variant="caption" sx={{ color: "gray" }}>
      ({value.homeTeamHalfScore} - {value.awayTeamHalfScore})
    </Typography>
    <Typography variant="body2" sx={{ color: "#467dfd", fontWeight: "bold" }}>
      {value.matchStatus} {value.matchTime}
    </Typography>
  </Grid>
));

// 賠率信息組件
const OddsInfo = memo(({ value }) => (
  <Grid container justifyContent="space-around">
    <OddsBox label="主" odds={value.homeTeamOdds} hdcOdds={value.homeHDCOdds} />
    <DrawOdds odds={value.drawOdds} />
    <OddsBox label="客" odds={value.awayTeamOdds} hdcOdds={value.awayHDCOdds} />
  </Grid>
));

// 賠率框組件
const OddsBox = memo(({ label, odds, hdcOdds }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
    }}
  >
    <Typography variant="body1" color="text.primary">
      {label} @{odds}
    </Typography>
    <Typography variant="body1" color="text.secondary">
      {hdcOdds}
    </Typography>
  </Box>
));

// 和局賠率組件
const DrawOdds = memo(({ odds }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Typography variant="body1" color="text.primary">
      和 @{odds}
    </Typography>
  </Box>
));

// 主組件
export const SoccerMatchCard = memo(({ groupedMatches = {} }) => (
  <>
    {Object.entries(groupedMatches)?.map(
      ([leagueName, matches], leagueIndex) => (
        <div key={leagueIndex}>
          <Grid size={{xs: 12}}>
            <Typography variant="body1" sx={styles.leagueHeader}>
              {leagueName}
            </Typography>
          </Grid>

          {matches.map((value, index) => (
            <Card key={index} sx={styles.matchCard}>
              <CardContent>
                <MatchHeader value={value} />

                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TeamInfo
                    teamName={value.homeTeamName}
                    teamLogo={value.homeTeamLogo}
                  />
                  <ScoreInfo value={value} />
                  <TeamInfo
                    teamName={value.awayTeamName}
                    teamLogo={value.awayTeamLogo}
                  />
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <OddsInfo value={value} />
              </CardContent>
            </Card>
          ))}
        </div>
      )
    )}
  </>
));
