import { AppBar, Typography, Box, Toolbar } from "@mui/material";
import Grid from "@mui/material/Grid2";

export const Bar = () => {
  return (
    <Grid container sx={{mb: 2}}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar variant="dense">
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              iSoccer Pro
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
    </Grid>
  );
};
