import React, { useEffect, useState } from "react";
import { useSoccerData } from "./useSoccerData";
import { CssBaseline, Container, ThemeProvider } from "@mui/material";


import Grid from "@mui/material/Grid2";
import { SoccerMatchCard } from "./components/soccerCard";
import { SoccerDateTab } from "./components/soceerDate";

export const Soccer = () => {

  const [selectedSoccerDate, setSelectedSoccerDate] = useState("");

  const { soccerData, soccerDateList, currentDate } =
    useSoccerData(selectedSoccerDate);

  const handleChangeSoccerDate = (event, soccerDate) => {
    console.log(soccerDate);
    setSelectedSoccerDate(soccerDate);
  };

  useEffect(() => {
    // Set the initial selected date to the first available one, if it's not set already
    if (soccerDateList?.length > 0 && !selectedSoccerDate) {
      const selected = soccerDateList[0];
      setSelectedSoccerDate(selected);
    }
  }, [soccerDateList, selectedSoccerDate]);

  return (
   
      <Container maxWidth="lg" sx={{ mt: 10, mb: 10, pl: 3, pr: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid size={{ xs: 12 }}>
            <SoccerDateTab
              soccerDate={soccerDateList}
              selectedSoccerDate={currentDate || selectedSoccerDate}
              onSeclectedSoccerDate={handleChangeSoccerDate}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SoccerMatchCard groupedMatches={soccerData} />
          </Grid>
        </Grid>
      </Container>
    
   
  );
};
