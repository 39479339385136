import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  redirect,
} from "react-router-dom";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { CssBaseline, Box } from "@mui/material";
import { Soccer } from "../Soccer";
import { SoccerHistory } from "../Soccer/components/SoccerHistory";
import { Bar } from "../AppBar";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function App() {
  return (
    <CssBaseline>
      <Router>
        <Wrapper>
          <Bar />
          <Routes>
            <Route path="/" element={<Soccer />} />
            <Route path="/history" element={<SoccerHistory />} />
          </Routes>
        </Wrapper>
      </Router>
    </CssBaseline>
  );
}
