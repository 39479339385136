import { useEffect, useState } from "react";

export const useTimer = (sec, ...val) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTrigger((s) => !s);
    }, sec * 1000);
    return () => clearInterval(timer);
  }, val);
  return trigger;
};
