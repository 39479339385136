import React, { memo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tabs,
  Tab,
  LinearProgress,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StatisticsTab } from './StatisticsTab';
import { HeadToHeadTab } from './HeadToHeadTab';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: 14,
}));

const ResultChip = memo(({ result }) => {
  const getColor = () => {
    switch (result) {
      case "W":
        return "success";
      case "L":
        return "error";
      case "D":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <Chip label={result} color={getColor()} size="small" sx={{ width: 40 }} />
  );
});

const StatBar = memo(({ value, total, color }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <LinearProgress
      variant="determinate"
      value={(value / total) * 100}
      color={color}
      sx={{ width: 100, height: 8, borderRadius: 4 }}
    />
    <Typography variant="body2">{value}</Typography>
  </Box>
));

const TeamStats = memo(({ stats }) => (
  <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
    <Typography variant="h6" gutterBottom>
      球隊數據
    </Typography>
    <Box sx={{ display: "flex", gap: 4 }}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          進球
        </Typography>
        <StatBar value={stats.goals} total={50} color="success" />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          失球
        </Typography>
        <StatBar value={stats.conceded} total={50} color="error" />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          控球率
        </Typography>
        <StatBar value={stats.possession} total={100} color="primary" />
      </Box>
    </Box>
  </Box>
));

const MatchHistory = memo(({ matches }) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>日期</StyledTableCell>
          <StyledTableCell>賽事</StyledTableCell>
          <StyledTableCell>主隊</StyledTableCell>
          <StyledTableCell align="center">比分</StyledTableCell>
          <StyledTableCell>客隊</StyledTableCell>
          <StyledTableCell align="center">結果</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {matches.map((match, index) => (
          <TableRow key={index} hover>
            <TableCell>{match.date}</TableCell>
            <TableCell>{match.competition}</TableCell>
            <TableCell>{match.homeTeam}</TableCell>
            <TableCell align="center">{match.score}</TableCell>
            <TableCell>{match.awayTeam}</TableCell>
            <TableCell align="center">
              <ResultChip result={match.result} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
));

export const SoccerHistory = memo(() => {
  const [tabValue, setTabValue] = React.useState(0);

  // 模擬數據
  const mockStats = {
    goals: 35,
    conceded: 20,
    possession: 55,
  };

  const mockMatches = [
    {
      date: "2024-03-15",
      competition: "英超",
      homeTeam: "曼城",
      awayTeam: "利物浦",
      score: "2-1",
      result: "W",
    },
    {
      date: "2024-03-10",
      competition: "足總盃",
      homeTeam: "曼城",
      awayTeam: "紐卡斯爾",
      score: "1-1",
      result: "D",
    },
    // ... 更多比賽數據
  ];

  // 渲染不同的標籤頁內容
  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <>
            <TeamStats stats={mockStats} />
            <Box sx={{ mt: 2 }}>
              <MatchHistory matches={mockMatches} />
            </Box>
          </>
        );
      case 1:
        return <StatisticsTab />;
      case 2:
        return <HeadToHeadTab />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 1200, margin: "0 auto", p: 2, mt: 8 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="h5">
          球隊歷史數據
        </Typography>
        
        {/* 可以添加一些篩選或設置按鈕 */}
      </Box>
      
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Tab label="近期比賽" />
          <Tab label="數據統計" />
          <Tab label="對戰記錄" />
        </Tabs>
      </Paper>

      <Box sx={{ 
        bgcolor: "background.paper", 
        borderRadius: 1,
        minHeight: "60vh"
      }}>
        {renderTabContent()}
      </Box>
    </Box>
  );
});
