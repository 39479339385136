import React, { memo } from 'react';
import {
  Box,
 
  Paper,
  Typography,
  Card,
  CardContent,
  Avatar,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import Grid from '@mui/material/Grid2'
import { styled } from '@mui/material/styles';

// 自定義樣式
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: 14,
}));

// 對戰概況組件
const HeadToHeadSummary = memo(({ team1, team2, stats }) => (
  <Paper sx={{ p: 3, mb: 3 }}>
    <Grid container alignItems="center" spacing={2}>
      {/* 主隊信息 */}
      <Grid size={{xs: 4}} sx={{ textAlign: 'center' }}>
        <Avatar
          src={team1.logo}
          alt={team1.name}
          sx={{ width: 80, height: 80, mx: 'auto', mb: 1 }}
        />
        <Typography variant="h6">{team1.name}</Typography>
      </Grid>

      {/* 對戰統計 */}
      <Grid size={{xs: 4}} sx={{ textAlign: 'center' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-around',
          p: 2,
          bgcolor: 'background.default',
          borderRadius: 1
        }}>
          <Box>
            <Typography variant="h4" color="success.main">{stats.team1Wins}</Typography>
            <Typography variant="body2" color="text.secondary">勝</Typography>
          </Box>
          <Box>
            <Typography variant="h4" color="warning.main">{stats.draws}</Typography>
            <Typography variant="body2" color="text.secondary">平</Typography>
          </Box>
          <Box>
            <Typography variant="h4" color="error.main">{stats.team2Wins}</Typography>
            <Typography variant="body2" color="text.secondary">負</Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ mt: 1 }}>
          近{stats.totalMatches}場比賽
        </Typography>
      </Grid>

      {/* 客隊信息 */}
      <Grid size={{xs: 4}} sx={{ textAlign: 'center' }}>
        <Avatar
          src={team2.logo}
          alt={team2.name}
          sx={{ width: 80, height: 80, mx: 'auto', mb: 1 }}
        />
        <Typography variant="h6">{team2.name}</Typography>
      </Grid>
    </Grid>
  </Paper>
));

// 進球統計組件
const GoalsStats = memo(({ stats }) => (
  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid size={{xs: 12, md: 6}}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>進球分布</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Typography>上半場</Typography>
            <Typography>{stats.firstHalfGoals} 球</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Typography>下半場</Typography>
            <Typography>{stats.secondHalfGoals} 球</Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
    <Grid size={{xs: 12, md: 6}}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>場均數據</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Typography>場均進球</Typography>
            <Typography>{stats.averageGoals}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Typography>場均角球</Typography>
            <Typography>{stats.averageCorners}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
));

// 歷史戰績表格
const MatchHistory = memo(({ matches }) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>日期</StyledTableCell>
          <StyledTableCell>賽事</StyledTableCell>
          <StyledTableCell>主隊</StyledTableCell>
          <StyledTableCell align="center">比分</StyledTableCell>
          <StyledTableCell>客隊</StyledTableCell>
          <StyledTableCell align="center">半場</StyledTableCell>
          <StyledTableCell align="center">角球</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {matches.map((match, index) => (
          <TableRow key={index} hover>
            <TableCell>{match.date}</TableCell>
            <TableCell>
              <Chip 
                label={match.competition} 
                size="small" 
                color="primary" 
                variant="outlined"
              />
            </TableCell>
            <TableCell>{match.homeTeam}</TableCell>
            <TableCell align="center">
              <Typography 
                color={match.result === 'W' ? 'success.main' : 
                       match.result === 'L' ? 'error.main' : 
                       'warning.main'}
              >
                {match.score}
              </Typography>
            </TableCell>
            <TableCell>{match.awayTeam}</TableCell>
            <TableCell align="center">{match.halfTimeScore}</TableCell>
            <TableCell align="center">{match.corners}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
));

export const HeadToHeadTab = memo(() => {
  // 模擬數據
  const mockData = {
    team1: {
      name: '曼城',
      logo: 'https://example.com/mancity.png',
    },
    team2: {
      name: '利物浦',
      logo: 'https://example.com/liverpool.png',
    },
    stats: {
      team1Wins: 15,
      draws: 8,
      team2Wins: 12,
      totalMatches: 35,
      firstHalfGoals: 28,
      secondHalfGoals: 35,
      averageGoals: 2.8,
      averageCorners: 9.5,
    },
    matches: [
      {
        date: '2024-03-10',
        competition: '英超',
        homeTeam: '曼城',
        awayTeam: '利物浦',
        score: '2-1',
        halfTimeScore: '1-0',
        corners: '7-3',
        result: 'W'
      },
      // ... 更多比賽記錄
    ]
  };

  return (
    <Box sx={{ p: 2 }}>
      <HeadToHeadSummary 
        team1={mockData.team1}
        team2={mockData.team2}
        stats={mockData.stats}
      />
      
      <GoalsStats stats={mockData.stats} />
      
      <Typography variant="h6" sx={{ mb: 2 }}>
        近期交鋒記錄
      </Typography>
      
      <MatchHistory matches={mockData.matches} />
    </Box>
  );
}); 