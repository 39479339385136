import { useEffect, useState } from "react";
import { getSoccerList, getMatchDateList } from "../../api/soccer";
import { LeagueName, MatchStatus } from "../../data/football";
import { useTimer } from "./useTimer";
import { parse, format } from "date-fns";

// Helper function to parse UpdateDateTime
const parseUpdateDateTime = (updateDateTime) => {
  if (!updateDateTime) return null;

  // Use date-fns to safely parse the date in "dd/MM/yyyy HH:mm:ss" format
  const parsedDate = parse(updateDateTime, "dd/MM/yyyy HH:mm:ss", new Date());

  // Handle invalid dates more gracefully
  return isNaN(parsedDate) ? null : parsedDate;
};

// Helper function to calculate match time based on UpdateDateTime and match status
const calculateMatchTime = (updateDateTime, matchStatus) => {
  const lastUpdateTime = parseUpdateDateTime(updateDateTime);
  if (!lastUpdateTime) return null; // Return null if the date is invalid

  const currentTime = new Date();

  // Calculate the difference in milliseconds between current time and last update time
  const timeDifference = currentTime - lastUpdateTime;

  // Convert the difference to minutes
  const minuteDifference = Math.floor(timeDifference / (1000 * 60));

  // Logic based on match status
  if (matchStatus === "上半場") {
    if (minuteDifference > 45) {
      // If extra time in the first half, show as "45+X'"
      return `45+${minuteDifference - 45}'`;
    }
    return `${minuteDifference}'`; // Normal first half
  }

  if (matchStatus === "半場") {
    // If it's halftime, return an empty string
    return "";
  }

  if (matchStatus === "下半場") {
    const secondHalfMinutes = 45 + minuteDifference; // Add 45 to indicate the second half

    if (secondHalfMinutes > 90) {
      // If extra time in the second half, show as "90+X'"
      return `90+${secondHalfMinutes - 90}'`;
    }

    return `${secondHalfMinutes}'`; // Normal second half
  }

  if (matchStatus === "未開賽") {
    return "";
  }

  return null;
};

// Main hook to fetch and manage soccer data
export const useSoccerData = (date) => {
  const [soccerData, setSoccerData] = useState({});
  const [soccerDateList, setSoccerDateList] = useState([]);
  const [currentDate, setCurrentDate] = useState(date);
  const _60sTimer = useTimer(60);

  useEffect(() => {
    getMatchDateList().then(async (res) => {
      if (res.data === null) return;
      const data = res.data;
      setSoccerDateList(data);
      const defaultDate = data?.length > 0 ? data[0] : null;
      const selectedDate = date && data?.includes(date) ? date : defaultDate;
      setCurrentDate(selectedDate);
    });
  }, [date]);

  useEffect(() => {
    getSoccerList(currentDate).then(async (res) => {
      if (res.data === null) return;
      const data = res.data;

      const groupedData = data[currentDate]?.reduce((acc, value) => {
        const originalLeagueName = value?.LeagueC;
        const mappedLeagueName = LeagueName?.[originalLeagueName] || originalLeagueName;
        const matchStatus = MatchStatus?.[value.Progress?.MatchProgress];
        const matchTime = calculateMatchTime(value.Progress?.UpdateDateTime, matchStatus);

        const HDAPoolInfo = value.Pools?.PoolInfo?.find((a) => a.Pool === 'HAD');
        const HDCPoolInfo = value.Pools?.PoolInfo?.find((a) => a.Pool === "HDC");
        const hdc = HDCPoolInfo?.Line?.[0]?.OddsSet?.[0]?.OddsInfo;
        const hda = HDAPoolInfo?.Line?.[0]?.OddsSet?.[0]?.OddsInfo;

        const matchData = {
          homeTeamName: value?.HomeC,
          awayTeamName: value?.AwayC,
          kickOffTime: value.MatchDateTime?.split(" ")[1],
          homeTeamLogo: value?.HomeTeamLogoID,
          awayTeamLogo: value?.AwayTeamLogoID,
          frontendID: value?.FrontEndID,
          leagueName: mappedLeagueName,
          homeTeamFullScore: value?.Progress?.Result?.split(":")?.[0]?.trim() || "",
          awayTeamFullScore: value?.Progress?.Result?.split(":")?.[1]?.trim() || "",
          homeTeamHalfScore: value?.ResultHalfTime?.split(":")?.[0] || "",
          awayTeamHalfScore: value?.ResultHalfTime?.split(":")?.[1] || "",
          isESOffer: value.IsESOffer === "1",
          inPlay: value?.Inplay === "1",
          chlInplay: value?.CHLOInplay === "1",
          matchStatus: matchStatus,
          matchTime: matchTime,
          matchStatusEx: value?.Progress?.MatchProgress,
          corner: value?.Progress?.RCCorner || "0",
          homeTeamOdds: hda?.[2]?.Odds,
          awayTeamOdds: hda?.[0]?.Odds,
          drawOdds: hda?.[1]?.Odds,
          homeHDCOdds: `主 [${hdc?.[1]?.Condition || "-"}] @${hdc?.[1]?.Odds || '--'}`,
          awayHDCOdds: `客 [${hdc?.[0]?.Condition || '-'}] @${hdc?.[0]?.Odds || '--'}`
        };

        // Group by league name
        if (!acc[mappedLeagueName]) {
          acc[mappedLeagueName] = [];
        }
        acc[mappedLeagueName].push(matchData);

        return acc;
      }, {});

      console.log(groupedData)

      setSoccerData(groupedData);
    });
  }, [currentDate, _60sTimer]);

  return {
    soccerData,
    soccerDateList,
    currentDate,
  };
};